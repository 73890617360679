//Theme Base Colour
$theme-color: 'teal'; //used to load the right quotemark icon only

// Colours (All themes - but used in this settings file so need them here)
$color-verylightgrey: 			#fafafa; // Tile bg color
$color-brownish-grey: 			#717067; // text default
$color-greyish-brown:			#3d3935; //sidebar text
$color-white: 					#fff; 

// background: linear-gradient(135deg, #6AC9C8 0%, #007B80 100%);
// background-color: #18BCB9;
//color: #003739; headings 
// Colours (rehab specific)
$color-lightteal:				#99D6D5; //stats
$color-teal: 					#18BCB9; //button bg colour
$color-darkteal:				#007B80; //bg on tile headings
$color-darkerteal:				#003739; //headings


$color-othergreen: 				#50a45a; //sidebar background

$color-primary: $color-teal; 
$color-secondary: $color-darkteal; 
$color-tertiary: $color-darkerteal;
$selection-color: $color-primary;
$selection-text-color: #fff;

//typography
$font-color: $color-brownish-grey;
$highlight-color: $selection-color;
$highlight-text-color: $selection-text-color;

$heading-color: $color-tertiary;
$link-color: $color-primary;
$link-color-hover: $color-secondary;

//backgrounds
$color-pagination-bg: $color-primary;
$color-sidebar-bg: $color-secondary;

//gradients
$gradient-start: $color-teal;
$gradient-end: $color-darkteal;
$gradient-hr-bg: linear-gradient(to right, $gradient-start, $gradient-end);

//block specific
$color-stats: $color-lightteal;
$color-tile-arrowbg: $color-primary;
$color-tile-titlebg: $color-secondary;
$stat-counter-bg-image: url('/public/img/jpg/counters-rehab-bg@2x.jpg');
$stat-counter-bg-position-desktop: 100% 50%;

$color-search-bar: $color-tertiary;
$file-attachment-linkbg: $color-secondary;
$file-attachment-linkiconbg: $color-primary;

//typography overrides
$button-bg-color: $color-teal;

//sidebar
$sidebar-triangle-background: url('/public/img/png/ic/triangle-teal.png');
$color-sidebarlinkactive-bg: $color-tertiary;

//Specialist Page 
$color-specialist-page-circle-bg: $color-tertiary;

//Header
$mobile-menu-toggler-bg-hover: darken($color-tertiary, 10%);

//MENU - Desktop
$desktop-menu-activeandhover: $color-secondary;
$desktop-menu-lvl1-hover: $color-secondary;

// Mobile
$mobile-menu-toggle-arrow-bg-image: url('/public/img/svg/ic/expand-rehab.svg'); 
// --Menu
$mobile-menu-toggler-bg: $color-tertiary;
//lvl1
$mobile-menu-lvl1-bg: $color-tertiary; 
$mobile-menu-lvl1-bg-active: $color-secondary; 
//lvl2
$mobile-menu-item-lvl2: $color-greyish-brown;
//lvl3
$mobile-menu-item-lvl3: $color-greyish-brown;